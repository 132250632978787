import { useCallback } from 'react';
import type { RouteModel } from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { parseUrl } from '@qonto/react-migration-toolkit/react/utils/url';
import type { UrlObject } from '@qonto/react-migration-toolkit/react/types/router';
import type { NavigateOptions, PolymorphicNavigate } from '@repo/poly-hooks/navigate';

export const useNavigate = (): PolymorphicNavigate => {
  // eslint-disable-next-line @qonto/no-use-ember-service-router -- This emberRouter is necessary until we have react-router available
  const emberRouter = useEmberService('router');

  return useCallback(
    (url: string | UrlObject, options: NavigateOptions = {}): Promise<boolean> => {
      const parsedUrl = parseUrl(url);
      const { queryParams, params } = emberRouter.recognize(parsedUrl);
      const models = Object.values(params) as RouteModel[];
      let transition: Transition<boolean>;
      if (options.replace) {
        transition = emberRouter.replaceWith(parsedUrl, models, {
          queryParams,
        }) as Transition<boolean>;
      } else {
        transition = emberRouter.transitionTo(parsedUrl, models, {
          queryParams,
        }) as Transition<boolean>;
      }
      return transition.promise;
    },
    [emberRouter]
  );
};

import { type HTMLAttributes, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Layout } from '../layout';

type DisputedProps = Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>;

export function Disputed(props: DisputedProps): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');

  return (
    <Layout
      title={formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.disputed.title',
      })}
      {...props}
    >
      <FormattedMessage
        id="transactions.sidebar.helper.dispute.errors.disputed.description.body"
        values={{
          learnMoreLink: (
            <a
              className="body-link"
              data-test-disputed-claim-link
              href={formatMessage(
                {
                  id: 'transactions.sidebar.helper.dispute.errors.disputed.description.url',
                },
                {
                  faqUrl: zendeskLocalization.getLocalizedArticle(9015327),
                }
              )}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="transactions.sidebar.helper.dispute.learn-more" />
            </a>
          ),
        }}
      />
    </Layout>
  );
}

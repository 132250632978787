import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OperationMethod } from '../graphql';

export function useTransactionOperationMethodLabels(): Record<OperationMethod, string> {
  const { formatMessage } = useIntl();
  return useMemo(() => {
    return {
      [OperationMethod.Biller]: formatMessage({ id: 'transactions.operation-types.biller' }),
      [OperationMethod.Card]: formatMessage({ id: 'transactions.operation-types.card' }),
      [OperationMethod.CardAcquirerPayout]: formatMessage({
        id: 'transactions.operation-types.card-acquirer-payout',
      }),
      [OperationMethod.Cheque]: formatMessage({ id: 'transactions.operation-types.cheque' }),
      [OperationMethod.DirectDebit]: formatMessage({
        id: 'transactions.operation-types.direct-debit',
      }),
      [OperationMethod.DirectDebitHold]: formatMessage({
        id: 'transactions.operation-types.direct-debit-hold',
      }),
      [OperationMethod.PagopaPayment]: formatMessage({
        id: 'transactions.operation-types.pagopa-payment',
      }),
      [OperationMethod.Tax]: formatMessage({ id: 'transactions.operation-types.tax' }),
      [OperationMethod.Transfer]: formatMessage({ id: 'transactions.operation-types.transfer' }),
      [OperationMethod.PayLater]: formatMessage({ id: 'transactions.operation-types.pay-later' }),
      [OperationMethod.Other]: formatMessage({ id: 'transactions.operation-types.unknown' }),
    };
  }, [formatMessage]);
}

export function useTransactionOperationMethodLabel(operationMethod: OperationMethod): string {
  const labels = useTransactionOperationMethodLabels();
  return labels[operationMethod];
}

import { useQuery } from '@tanstack/react-query';
import { cashFlowCategoriesNamespaceV2 } from 'qonto/constants/hosts';
import type {
  CashflowTimeseries as CashflowTimeseriesApiResponse,
  CashflowTimeseriesRequest,
} from 'qonto/react/api/models/cash-flow-timeseries';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';
import type { QueryResult } from 'qonto/react/api/utils/query-result';
import { type CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';
import type Organization from 'qonto/models/organization';
import { type CashflowPeriod, type CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import ENV from 'qonto/config/environment';
import { getTimeframe } from '../utils/cash-flow-timeseries/period-timeframe-interval';
import { useFetchApi } from './use-fetch-api';

export async function fetchCashflowTimeseries(
  request: CashflowTimeseriesRequest,
  fetchApi: ReturnType<typeof useFetchApi>,
  namespace: string
): Promise<CashflowTimeseries> {
  const endpointUrl = `${namespace}/cash-flow/timeseries`;
  const response = await fetchApi(endpointUrl, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error('Error fetching cashflow timeseries');
  }

  const data = (await response.json()) as CashflowTimeseriesApiResponse;
  const timeseries = camelizeKeys(data) as CashflowTimeseries;

  return timeseries;
}

export const useCashflowTimeseries = (
  request: CashflowTimeseriesRequest,
  key: string[]
): QueryResult<CashflowTimeseries> => {
  const fetchApi = useFetchApi();

  const {
    data,
    isLoading: isPending,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['cashflow-timeseries', ...key],
    queryFn: () => fetchCashflowTimeseries(request, fetchApi, cashFlowCategoriesNamespaceV2),
    staleTime: 1000 * 60, // 1 min
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    retry: ENV.environment === 'test' ? false : 3,
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const prepareCashflowTimeseriesRequest = ({
  selectedFrequency,
  selectedPeriod,
  forecastedPeriods,
  organization,
  bankAccounts,
}: {
  selectedFrequency: CashflowPeriodRate | undefined;
  selectedPeriod: CashflowPeriod;
  forecastedPeriods: number | undefined;
  organization: Organization;
  bankAccounts: string;
}): {
  queryKey: string[];
  request: CashflowTimeseriesRequest;
} => {
  const timeframe = getTimeframe(
    selectedFrequency,
    selectedPeriod,
    organization,
    forecastedPeriods ?? 0
  );
  const queryKey = [
    organization.id,
    timeframe.start_year_month,
    timeframe.end_year_month,
    bankAccounts,
  ];
  if (selectedFrequency) queryKey.push(selectedFrequency);

  const bankAccountsCollection = bankAccounts ? bankAccounts.split(',') : undefined;

  const request: CashflowTimeseriesRequest = {
    bank_account_ids: bankAccountsCollection,
    ...timeframe,
    ...(selectedFrequency && { aggregation_interval: selectedFrequency }),
  };

  return {
    queryKey,
    request,
  };
};

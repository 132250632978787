import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import { transactionNamespace } from 'qonto/constants/hosts';
import { camelizeKeys } from '../api/utils/camelize-keys';
import { useFetchApi } from './use-fetch-api';

export function useFetchTransaction<TransactionModel>(transactionId?: string): UseQueryResult<
  CamelCasedPropertiesDeep<
    TransactionModel,
    {
      preserveConsecutiveUppercase: true;
    }
  >
> {
  const fetchApi = useFetchApi();

  return useQuery({
    queryKey: ['transacion', transactionId],
    queryFn: async () => {
      const response = await fetchApi(`${transactionNamespace}/transactions/${transactionId}`);

      if (!response.ok) {
        throw new Error('Failed to fetch transaction');
      }

      const { transaction } = (await response.json()) as unknown as {
        transaction: TransactionModel;
      };

      return camelizeKeys(transaction);
    },
  });
}

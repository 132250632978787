import React, { useState } from 'react';
import { ContentCategory as DKContentCategory } from '@repo/domain-kit/accounts-receivable';
import {
  ColumnId,
  type Column,
  type ReceivableInvoicesSettingsModel,
} from 'qonto/react/receivable-invoices/api/models/receivable-invoices-settings';

interface ContentCategoryProps {
  settings: ReceivableInvoicesSettingsModel;
}

const defaultColumns = [
  { id: ColumnId.Description },
  { id: ColumnId.Quantity },
  { id: ColumnId.UnitPrice },
  { id: ColumnId.VatRate },
  { id: ColumnId.Subtotal },
];

export function ContentCategory({ settings }: ContentCategoryProps): React.ReactNode {
  const [columns, setColumns] = useState(settings.tableColumns ?? defaultColumns);

  const updateTableColumns = (newColumns: Column[]): void => {
    settings.tableColumns = newColumns;
    setColumns(newColumns);
  };

  const handleInvoiceTitleChange = (value: string): void => {
    settings.invoiceTitle = value;
  };
  const handleQuoteTitleChange = (value: string): void => {
    settings.quoteTitle = value;
  };
  const handleColumNamesSort = (sortedColumns: Column[]): void => {
    updateTableColumns(sortedColumns);
  };
  const handleColumNamesInputChange = ({
    id,
    value,
  }: {
    id: Column['id'];
    value: string;
  }): void => {
    const updatedColumns = columns.map(column =>
      column.id === id ? { ...column, label: value } : column
    );
    updateTableColumns(updatedColumns);
  };

  return (
    <DKContentCategory
      invoiceTitle={settings.invoiceTitle}
      items={columns}
      onColumnInputChange={handleColumNamesInputChange}
      onInvoiceTitleChange={handleInvoiceTitleChange}
      onQuoteTitleChange={handleQuoteTitleChange}
      onSort={handleColumNamesSort}
      quoteTitle={settings.quoteTitle}
    />
  );
}

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ENV from 'qonto/config/environment';
import { upcomingTransactionsNamespace } from 'qonto/constants/hosts.ts';
import { useFetchApi } from './use-fetch-api';

export interface UpcomingTransaction {
  counterparty_name: string;
  provider_object_type: string;
  due_date: string;
  side: 'credit' | 'debit';
  amount: {
    value: string;
    currency: string;
  };
  metadata?: {
    invoice_number: string;
  };
}

interface UpcomingTransactionsResponse {
  upcoming_transactions?: UpcomingTransaction[];
  meta?: {
    total_count: number;
    per_page: number;
    current_page: number;
  };
}

export interface UpcomingTransactionsResult {
  data: UpcomingTransactionsResponse | undefined;
  isPending: boolean;
  isError: boolean;
}

export const useUpcomingTransactions = (
  perPage: number,
  page: number,
  status: string
): UpcomingTransactionsResult => {
  const fetchApi = useFetchApi();
  const startDate = dayjs();
  const endDate = startDate.add(30, 'days');

  const formattedStartDate = startDate.format('YYYY-MM-DD');
  const formattedEndDate = endDate.format('YYYY-MM-DD');

  return useQuery({
    queryKey: ['upcoming-transactions', page, perPage],
    queryFn: async () => {
      const response = await fetchApi(
        `${upcomingTransactionsNamespace}/cash-flow/upcoming-transactions/search`,
        {
          method: 'POST',
          body: JSON.stringify({
            pagination: { per_page: perPage, page },
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            statuses: [status],
          }),
        }
      );
      const data: UpcomingTransactionsResponse =
        (await response.json()) as UpcomingTransactionsResponse;
      return data;
    },
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    retry: ENV.environment === 'test' ? false : 3,
  });
};

import type { ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ErrorState } from '@repo/design-system-kit';
import { FlowFeedback, FlowFeedbackStatus } from 'qonto/react/components/flows/screens';

interface OnboardingFeedbackProps {
  transitionToNext: () => void;
}

export function OnboardingFeedback({ transitionToNext }: OnboardingFeedbackProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const mollie = useEmberService('mollie');
  const sentry = useEmberService('sentry');

  let title, subtitle, capabilities;

  if (mollie.isEnabled) {
    title = formatMessage({ id: 'payment-links.onboarding.feedback.success.title' });
    subtitle = formatMessage({ id: 'payment-links.onboarding.feedback.success.description' });
    capabilities = 'enabled';
  } else {
    title = formatMessage({ id: 'payment-links.onboarding.feedback.pending.title' });
    subtitle = formatMessage({ id: 'payment-links.onboarding.feedback.pending.description' });
    capabilities = 'pending';
  }

  const primaryActionLabel = formatMessage({
    id: 'payment-links.onboarding.feedback.cta.resume-invoice',
  });

  const onPrimaryActionClick = (): void => {
    segment.track('payment-links-confirmation-screen_cta_clicked', {
      cta: 'resume_invoice',
      capabilities,
    });
    transitionToNext();
  };

  const onRetry = (): void => {
    mollie.fetchConnectionTask.perform().catch(error => {
      sentry.captureException(error);
    });
  };

  if (mollie.isError) {
    return (
      <ErrorState
        onRetry={onRetry}
        retryButtonVariant="primary"
        subtitle={formatMessage({ id: 'payment-links.onboarding.feedback.error.description' })}
        title={formatMessage({ id: 'payment-links.onboarding.feedback.error.title' })}
      />
    );
  }

  const status: FlowFeedbackStatus = mollie.isEnabled
    ? FlowFeedbackStatus.Success
    : FlowFeedbackStatus.Pending;

  return (
    <FlowFeedback
      feedback={status}
      primaryAction={{
        label: primaryActionLabel,
        onClick: onPrimaryActionClick,
      }}
      subtitle={subtitle}
      title={title}
    />
  );
}

import { useCallback } from 'react';
import type { NavigateOptions } from '@repo/poly-hooks/navigate';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useNavigate } from 'qonto/react/hooks/use-navigate';

type OrganizationNavigation = (to: string, options?: NavigateOptions) => Promise<void>;

export const useOrganizationNavigation = (): OrganizationNavigation => {
  const { organization } = useOrganizationManager();
  const navigate = useNavigate();

  return useCallback(
    async (to, options) => {
      await navigate(`/organizations/${organization.slug}${to}`, options);
    },
    [navigate, organization.slug]
  );
};

import { type HTMLAttributes, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Layout } from '../layout';

interface CommercialProps extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void;
}

export function Commercial({ onClick, ...props }: CommercialProps): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');

  return (
    <Layout
      onClick={onClick}
      title={formatMessage({
        id: 'transactions.sidebar.helper.dispute.commercial.subtitle',
      })}
      {...props}
    >
      <FormattedMessage
        id="transactions.sidebar.helper.dispute.commercial.description.body"
        values={{
          learnMoreLink: (
            <a
              className="body-link"
              data-test-commercial-claim-link
              href={formatMessage(
                {
                  id: 'transactions.sidebar.helper.dispute.commercial.description.url',
                },
                {
                  faqUrl: zendeskLocalization.getLocalizedArticle(9015327),
                }
              )}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="transactions.sidebar.helper.dispute.learn-more" />
            </a>
          ),
        }}
      />
    </Layout>
  );
}

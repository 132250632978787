import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { transactionNamespace } from 'qonto/constants/hosts';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import type { TransactionModel } from 'qonto/react/models/transaction';
import { useRefetchTransaction } from './use-refetch-transaction';

export const useLinkTransactionAttachment = (
  transactionId: string
): UseMutationResult<TransactionModel, Error, string> => {
  const fetchApi = useFetchApi();
  const { mutate: refetchTransaction } = useRefetchTransaction(transactionId);

  const linkTransactionAttachment = async (attachmentId: string): Promise<TransactionModel> => {
    try {
      const response = await fetchApi(
        `${transactionNamespace}/transactions/${transactionId}/link_attachments`,
        {
          method: 'PATCH',
          body: JSON.stringify({
            transaction: {
              attachment_ids: [attachmentId],
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Could not link attachment');
      }

      const transaction = (await response.json()) as TransactionModel;
      return transaction;
    } catch (e) {
      throw new Error('Could not link attachment');
    }
  };

  return useMutation({
    mutationFn: linkTransactionAttachment,
    onSuccess: () => {
      refetchTransaction();
    },
    throwOnError: true,
  });
};

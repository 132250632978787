import { type HTMLAttributes, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Layout } from '../layout';

interface OutdatedProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  validityPeriodText: string;
}

export function Outdated({ validityPeriodText, ...props }: OutdatedProps): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');

  return (
    <Layout
      title={formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.too-old.title',
      })}
      {...props}
    >
      <FormattedMessage
        id="transactions.sidebar.helper.dispute.errors.too-old.description.body"
        values={{
          learnMoreLink: (
            <a
              className="body-link"
              data-test-outdated-claim-link
              href={formatMessage(
                {
                  id: 'transactions.sidebar.helper.dispute.errors.too-old.description.url',
                },
                {
                  faqUrl: zendeskLocalization.getLocalizedArticle(9015327),
                }
              )}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="transactions.sidebar.helper.dispute.learn-more" />
            </a>
          ),
          validityPeriod: (
            <span className="body-2" data-test-outdated-claim-validity-period>
              <FormattedMessage id={validityPeriodText} />
            </span>
          ),
        }}
      />
    </Layout>
  );
}

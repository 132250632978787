import cx from 'clsx';
import { type ReactNode } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { IconChevronRightOutlined } from '@repo/monochrome-icons';
import styles from './styles.strict-module.css';

interface CompanyCardProps {
  name?: string;
  legalNumber?: string;
  billingAddress?: BillingAddress;
  onPress: () => void;
}

interface BillingAddress {
  streetAddress?: string;
  city?: string;
  zipCode?: string;
  provinceCode?: string;
  countryCode?: string;
}

export function CompanyCard({
  name,
  legalNumber,
  billingAddress,
  onPress,
}: CompanyCardProps): ReactNode {
  const formatAddress = (address?: BillingAddress): string | undefined => {
    if (!address) {
      return;
    }

    const { streetAddress, city, zipCode } = address;
    const addressArray: string[] = [];
    const showComma = Boolean(zipCode || city);

    if (!streetAddress && !city && !zipCode) {
      return;
    }
    if (streetAddress) {
      addressArray.push(`${streetAddress}${showComma ? ',' : ''}`);
    }
    if (zipCode) {
      addressArray.push(zipCode);
    }
    if (city) {
      addressArray.push(city);
    }

    return addressArray.join(' ').toString();
  };

  const formattedAddress = formatAddress(billingAddress);

  return (
    <AriaButton
      className={styles['company-card']}
      data-testid="company-card"
      onPress={onPress}
      type="button"
    >
      <div className={styles['text-container']}>
        {Boolean(name) && (
          <span className={cx('body-1', styles.title)} data-testid="company-card-name">
            {name}
          </span>
        )}
        <div className={cx('body-2', styles.subtitle)}>
          {Boolean(!legalNumber) && Boolean(!formattedAddress) && <span> - </span>}
          {Boolean(legalNumber) && (
            <span data-testid="company-card-legal-number">{legalNumber}</span>
          )}
          {Boolean(legalNumber) && Boolean(formattedAddress) && <span> · </span>}
          {Boolean(formattedAddress) && (
            <span data-testid="company-card-address">{formattedAddress}</span>
          )}
        </div>
      </div>
      <div className={styles['chevron-icon']}>
        <IconChevronRightOutlined />
      </div>
    </AriaButton>
  );
}

import type { ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { FormattedMessage, useIntl } from 'react-intl';
import { LottiePlayer } from '@repo/design-system-kit';
import { FormattedMessage as FormattedHTMLMessage } from '@repo/domain-kit/shared';
import { FlowValueProposition } from 'qonto/react/components/flows/screens/value-proposition';
import styles from './intro.strict-module.css';

interface OnboardingIntroProps {
  transitionToNext: () => void;
}

export function OnboardingIntro({ transitionToNext }: OnboardingIntroProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const segment = useSegment();

  const onFaqClick = (): void => {
    segment.track('payment-link_faq_clicked', {
      origin: 'proposition_screen',
    });
  };
  const onActivateClick = (): void => {
    segment.track('payment-link_activation_clicked');
    transitionToNext();
  };

  let lottieUrl;
  switch (locale) {
    case 'de':
      lottieUrl = '/lotties/payment-links/lottie_vp_payment_link_desktop_de.json';
      break;
    case 'fr':
      lottieUrl = '/lotties/payment-links/lottie_vp_payment_link_desktop_fr.json';
      break;
    default:
      lottieUrl = '/lotties/payment-links/lottie_vp_payment_link_desktop_en.json';
  }

  return (
    <FlowValueProposition
      illustration={<LottiePlayer path={lottieUrl} />}
      illustrationClassName={styles.illustration}
    >
      <h1 className="title-2 mb-32">
        <FormattedMessage id="payment-links.onboarding.intro.title" />
      </h1>
      <h2 className="title-4 mb-8">
        <FormattedMessage id="payment-links.onboarding.intro.item1.title" />
      </h2>
      <p className="body-2 mb-16">
        <FormattedMessage
          id="payment-links.onboarding.intro.item1.subtitle"
          values={{
            linkMollie: (
              <a
                className="body-link"
                href={formatMessage({ id: 'payment-links.onboarding.intro.item1.link.url' })}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage id="payment-links.onboarding.intro.item1.link.text" />
              </a>
            ),
          }}
        />
      </p>
      <h2 className="title-4 mb-8">
        <FormattedMessage id="payment-links.onboarding.intro.item2.title" />
      </h2>
      <p className="body-2 mb-32">
        <FormattedHTMLMessage id="payment-links.onboarding.intro.item2.subtitle" />
      </p>

      <div className={styles.actions}>
        <a
          className="btn btn--secondary"
          href={formatMessage({ id: 'payment-links.onboarding.intro.learn-more-url' })}
          onClick={onFaqClick}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="payment-links.onboarding.intro.learn-more-cta" />
        </a>
        <button className="btn btn--primary" onClick={onActivateClick} type="button">
          <FormattedMessage id="payment-links.onboarding.intro.activate-cta" />
        </button>
      </div>
    </FlowValueProposition>
  );
}

import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout } from '../layout';

interface FailProps {
  errorText: string;
}

export function Fail({ errorText, ...props }: FailProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <Layout
      title={formatMessage({
        id: 'transactions.sidebar.helper.dispute.errors.fail-statuses.title',
      })}
      {...props}
    >
      <span className="body-2" data-test-fail-claim-status>
        <FormattedMessage
          id="transactions.sidebar.helper.dispute.errors.fail-statuses.description.body"
          values={{
            placeholder: errorText,
          }}
        />
      </span>
    </Layout>
  );
}
